import React, { FC, useCallback, useState } from 'react';
import styled from 'styles/styled-components';
import media from 'utils/mediaStyle';
import { FormattedMessage } from 'react-intl';
import messages from '../messages';
import { Product, TrackingAddToCartAddFromType, TrackingProductImpressionType } from 'types/schema';
import BrandCategories from './BrandCategories';
import MarketItem from 'components/MarketItem/MarketItem';
import { createStructuredSelector } from 'reselect';
import { ApplicationRootState } from 'types';
import { AddedProduct, AddingProduct } from 'containers/MainLayout/types';
import { selectBrandInfo, selectGroupBrands, selectLoading, selectNoteItemLoading } from '../selectors';
import { selectAddedProducts, selectAddingProducts, selectUserPermissions } from 'containers/MainLayout/selectors';
import { Dispatch } from 'redux';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import ModalNote from 'components/Checkout/ModalNote';
import { BrandInfo, BrandProduct, IGroupBrands } from '../types';
import { Button, Skeleton } from 'antd';
import { IHomeFavoritePayload, IHomeProductNotePayload } from 'containers/HomePage/types';
import { setFavirote, setProductNote, setViewMore } from '../actions';

const Container = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;

`;

const ProductQuantity = styled.div`
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  color: #424242;
`;

const ProductListRow = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: 12px;
  row-gap: 12px;

  ${media.sm`
    grid-template-columns: repeat(3, minmax(0, 1fr));  
  `};

  ${media.md`
    grid-template-columns: repeat(4, minmax(0, 1fr));
    column-gap: 24px;
    row-gap: 20px;
  `};

  ${media.lg`
    grid-template-columns: repeat(5, minmax(0, 1fr));
    column-gap: 24px;
    row-gap: 20px;
 `};
`;

const ProductItem = styled.div`
  border: 1px solid #eaeaeb !important;
  border-radius: 4px;
  ${media.md`
      border: none;
  `};
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
const ButtonContainer = styled(Button)`
  padding: 8 12px;
  height: 40px;
  border: 1px solid #43A047;
  border-radius: 8px;
  color: #43A047;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
`;

interface IStateProps {
  brandInfo: BrandInfo;
  loading: boolean;
  addedProducts: AddedProduct;
  addingProducts: AddingProduct;
  permissions: string[];
  noteItemLoading: boolean;
  groupBrands: IGroupBrands;
}

interface IDispatchProps {
  onToggleFavorite: (data: IHomeFavoritePayload) => void;
  onSetProductNote: (data: IHomeProductNotePayload) => void;
  gotoSignIn: () => void;
  onViewMore: (data: { brandId: string, page: number, isMainBrand: boolean }) => void;
}

interface IOwnProps {
  products: BrandProduct[];
  sectionName: string;
  totalResults: number;
}

type IProps = IStateProps & IDispatchProps & IOwnProps;

const ProductList: FC<IProps> = (props) => {
  const {
    products,
    addedProducts,
    addingProducts,
    permissions,
    noteItemLoading,
    onToggleFavorite,
    onSetProductNote,
    gotoSignIn,
    sectionName,
    brandInfo,
    groupBrands,
    onViewMore,
    totalResults
  } = props;

  const loggedIn = !!localStorage.getItem('token');

  const [selectedProduct, setSelectedProduct] = useState<Product>();
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const [isOpenNoteModal, setIsOpenNoteModal] = useState<boolean>(false);

  // Handlers
  const onClickOpenNoteModal = useCallback(
    (product: Product, index: number) => {
      if (loggedIn) {
        setSelectedProduct(product);
        setSelectedIndex(index);
        setIsOpenNoteModal(true);
      } else {
        gotoSignIn();
      }
    },
    [setSelectedProduct, setSelectedIndex, setIsOpenNoteModal, loggedIn],
  );

  const onClickCloseNoteModal = useCallback(() => {
    setSelectedProduct(undefined);
    setSelectedIndex(-1);
    setIsOpenNoteModal(false);
  }, [setSelectedProduct, setSelectedIndex, setIsOpenNoteModal]);

  const onSubmitAddingNote = useCallback(
    (note: string) => {
      if (selectedProduct) {
        onSetProductNote({
          index: selectedIndex,
          section: sectionName,
          note: note,
          id: selectedProduct.id,
        });
      }
      onClickCloseNoteModal();
    },
    [selectedProduct, sectionName, selectedIndex, onClickCloseNoteModal],
  );

  return (
    <Container>
      <ProductQuantity>
        {!!products?.length && products[0].loading ? (
          <Skeleton.Input active={true} size="default" />
        ) : (
          <>
            {totalResults} {totalResults > 1 ? <FormattedMessage {...messages.products} defaultMessage="sản phẩm" /> : <FormattedMessage {...messages.product} defaultMessage="sản phẩm" />}
          </>
        )}
      </ProductQuantity>
      <BrandCategories />
      {!!products?.length && (
        <ProductListRow>
          {products?.map((product, index) => (
            <ProductItem key={product.id}>
              <MarketItem
                vertialOnly
                listName="Section Product List"
                loggedIn={loggedIn}
                gotoSignIn={gotoSignIn}
                product={product}
                loading={product.loading}
                favorite={product.isFavorite}
                note={product.note}
                added={addedProducts[product.id]}
                adding={addingProducts[product.id]}
                position={index}
                permissions={permissions}
                onToggleFavorite={() => onToggleFavorite({ product: product, index: index, section: sectionName })}
                onOpenNote={() => onClickOpenNoteModal(product, index)}
                onDeleteNote={() => {
                  onSetProductNote({ index: index, section: sectionName, note: '', id: product.id });
                  onClickCloseNoteModal();
                }}
                impressionProperties={{ type: TrackingProductImpressionType.CartProduct }}
                addToCartProperties={{ addFrom: TrackingAddToCartAddFromType.CartProduct }}
              />
            </ProductItem>
          ))}
        </ProductListRow>
      )}
      {groupBrands[brandInfo.id]?.totalResults > groupBrands[brandInfo.id]?.data.length && <ButtonWrapper onClick={() => {
        onViewMore({ brandId: brandInfo.id, page: groupBrands[brandInfo.id].page + 1, isMainBrand: true });
      }}>
        <ButtonContainer>
          <FormattedMessage {...messages.viewMore} />
        </ButtonContainer>
      </ButtonWrapper>}

      <ModalNote
        headerLabel={selectedProduct?.name || ''}
        isOpen={isOpenNoteModal}
        confirmLoading={noteItemLoading}
        onSubmit={onSubmitAddingNote}
        onClose={onClickCloseNoteModal}
        note={selectedProduct?.note || ''}
      />
    </Container>
  );
};

const mapStateToProps = createStructuredSelector<ApplicationRootState, IStateProps>({
  brandInfo: selectBrandInfo(),
  groupBrands: selectGroupBrands(),
  loading: selectLoading(),
  addedProducts: selectAddedProducts(),
  addingProducts: selectAddingProducts(),
  permissions: selectUserPermissions(),
  noteItemLoading: selectNoteItemLoading(),
});
const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => ({
  onToggleFavorite: (data: IHomeFavoritePayload) => dispatch(setFavirote(data)),
  onSetProductNote: (data: IHomeProductNotePayload) => dispatch(setProductNote(data)),
  gotoSignIn: () => dispatch(push('/signin')),
  onViewMore: (data: { brandId: string, page: number, isMainBrand: boolean }) =>
    dispatch(
      setViewMore(data),
    ),
});

const withConnect = connect<IStateProps, IDispatchProps, IOwnProps>(mapStateToProps, mapDispatchToProps);

export default withConnect(ProductList);
