import { createSelector } from 'reselect';
import { ApplicationRootState } from 'types';
import { initialState } from './reducer';

const selectBrandDetailPageDomain = (state: ApplicationRootState) => state.brandDetailPage || initialState;

const selectBrandDetailPage = () => createSelector(selectBrandDetailPageDomain, (substate) => substate);

const selectLoading = () => createSelector(selectBrandDetailPageDomain, (substate) => substate.loading);
const selectSimilarBrands = () => createSelector(selectBrandDetailPageDomain, (substate) => substate.similarBrands);
const selectNoteItemLoading = () => createSelector(selectBrandDetailPageDomain, (substate) => substate.noteItemLoading);
const selectBrandInfo = () => createSelector(selectBrandDetailPageDomain, (substate) => substate.brandInfo);
const selectCategories = () => createSelector(selectBrandDetailPageDomain, (substate) => substate.categories);
const selectGroupBrands = () => createSelector(selectBrandDetailPageDomain, (substate) => substate.groupBrands);
const selectCategoryIds= () => createSelector(selectBrandDetailPageDomain, (substate) => substate.categoryIds);
export default selectBrandDetailPage;
export {
  selectBrandDetailPageDomain,
  selectLoading,
  selectNoteItemLoading,
  selectSimilarBrands,
  selectBrandInfo,
  selectCategories,
  selectGroupBrands,
  selectCategoryIds
};
