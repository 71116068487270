import React, { useCallback, useState } from 'react';
import styled from 'styles/styled-components';
import media from 'utils/mediaStyle';
import { IGroupBrands } from '../types';
import { getInitials } from 'utils/getInitialsBrandName';
import MarketItem from 'components/MarketItem/MarketItem';
import { Product, TrackingAddToCartAddFromType, TrackingProductImpressionType } from 'types/schema';
import { AddedProduct, AddingProduct } from 'containers/MainLayout/types';
import { createStructuredSelector } from 'reselect';
import { ApplicationRootState } from 'types';
import { selectLoading, selectNoteItemLoading } from '../selectors';
import { selectAddedProducts, selectAddingProducts, selectUserPermissions } from 'containers/MainLayout/selectors';
import { Dispatch } from 'redux';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import ModalNote from 'components/Checkout/ModalNote';
import { IHomeFavoritePayload, IHomeProductNotePayload } from 'containers/HomePage/types';
import { setFavirote, setProductNote, setViewMore } from '../actions';
import { Button, Divider } from 'antd';
import { FormattedMessage } from 'react-intl';
import messages from '../messages';

const Container = styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 32px;

    ${media.md`
        margin-top: 40px;
        gap: 40px;
    `};
`

const BrandProducts = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`

const LogoWrapper = styled.div`

  width: 60px;
  aspect-ratio: 1 / 1;
  border-radius: 6px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  background-color: #ffffff;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  color: #242527;
  display: flex;
  justify-content: center;
  align-items: center;

  ${media.md`
    width: 80px;
  `};
`;

const Logo = styled.img`
  border-radius: 6px;
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const ProductListRow = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: 12px;
  row-gap: 12px;

  ${media.sm`
    grid-template-columns: repeat(3, minmax(0, 1fr));  
  `};

  ${media.md`
    grid-template-columns: repeat(4, minmax(0, 1fr));
    column-gap: 24px;
    row-gap: 20px;
  `};

  ${media.lg`
    grid-template-columns: repeat(5, minmax(0, 1fr));
    column-gap: 24px;
    row-gap: 20px;
 `};
`;

const ProductItem = styled.div`
  border: 1px solid #eaeaeb !important;
  border-radius: 10px;
  ${media.md`
        border: none;
    `};
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
const ButtonContainer = styled(Button)`
  padding: 8 12px;
  height: 40px;
  border: 1px solid #43A047;
  border-radius: 8px;
  color: #43A047;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
`;

interface IStateProps {
    loading: boolean;
    addedProducts: AddedProduct;
    addingProducts: AddingProduct;
    permissions: string[];
    noteItemLoading: boolean;
}

interface IDispatchProps {
    onToggleFavorite: (data: IHomeFavoritePayload) => void;
    onSetProductNote: (data: IHomeProductNotePayload) => void;
    gotoSignIn: () => void;
    onViewMore: (data: { brandId: string, page: number }) => void;
}

interface IOwnProps {
    groupBrands: IGroupBrands;
    groupName: string;
    brandId: string;
}

type IProps = IStateProps & IDispatchProps & IOwnProps;


const GroupBrandProductList = (props: IProps) => {
    const {
        addedProducts,
        addingProducts,
        permissions,
        noteItemLoading,
        onToggleFavorite,
        onSetProductNote,
        gotoSignIn,
        groupBrands,
        onViewMore
    } = props;

    const loggedIn = !!localStorage.getItem('token');

    const [selectedProduct, setSelectedProduct] = useState<Product>();
    const [selectedIndex, setSelectedIndex] = useState<number>(-1);
    const [isOpenNoteModal, setIsOpenNoteModal] = useState<boolean>(false);
    const [selectedSection, setSelectedSection] = useState<string>();
    // Handlers
    const onClickOpenNoteModal = useCallback(
        (product: Product, index: number, section: string) => {
            if (loggedIn) {
                setSelectedSection(section);
                setSelectedProduct(product);
                setSelectedIndex(index);
                setIsOpenNoteModal(true);
            } else {
                gotoSignIn();
            }
        },
        [setSelectedProduct, setSelectedSection, setSelectedIndex, setIsOpenNoteModal, loggedIn],
    );

    const onClickCloseNoteModal = useCallback(() => {
        setSelectedProduct(undefined);
        setSelectedSection(undefined);
        setSelectedIndex(-1);
        setIsOpenNoteModal(false);
    }, [setSelectedProduct, setSelectedSection, setSelectedIndex, setIsOpenNoteModal]);

    const onSubmitAddingNote = useCallback(
        (note: string) => {
            if (selectedProduct) {
                onSetProductNote({
                    index: selectedIndex,
                    section: selectedSection || '',
                    note: note,
                    id: selectedProduct.id,
                });
            }
            onClickCloseNoteModal();
        },
        [selectedProduct, selectedSection, selectedIndex, onClickCloseNoteModal],
    );

    return (
        <Container>
            {Object.values(groupBrands)?.sort((a: any, b: any) => b?.data.length - a?.data.length)?.filter((item: any) => item.id !== props.brandId && item?.data.length > 0)?.map((groupBrandItem) => (

                <BrandProducts key={groupBrandItem?.id}>
                    <Divider>
                        <LogoWrapper>
                            {groupBrandItem?.logo ? <Logo src={groupBrandItem?.logo} loading="lazy" /> : getInitials(groupBrandItem?.name)}
                        </LogoWrapper>
                    </Divider>

                    {!!groupBrandItem?.data.length && (
                        <ProductListRow>
                            {groupBrandItem?.data?.map((product, index) => (
                                <ProductItem key={product.id}>
                                    <MarketItem
                                        vertialOnly
                                        listName="Section Product List"
                                        loggedIn={loggedIn}
                                        gotoSignIn={gotoSignIn}
                                        product={product}
                                        loading={product.loading}
                                        favorite={product.isFavorite}
                                        note={product.note}
                                        added={addedProducts[product.id]}
                                        adding={addingProducts[product.id]}
                                        position={index}
                                        permissions={permissions}
                                        onToggleFavorite={() => onToggleFavorite({ product: product, index: index, section: groupBrandItem?.id.toString() })}
                                        onOpenNote={() => onClickOpenNoteModal(product, index, groupBrandItem?.id.toString())}
                                        onDeleteNote={() => {
                                            onSetProductNote({ index: index, section: groupBrandItem?.id.toString(), note: '', id: product.id });
                                            onClickCloseNoteModal();
                                        }}
                                        impressionProperties={{ type: TrackingProductImpressionType.CartProduct }}
                                        addToCartProperties={{ addFrom: TrackingAddToCartAddFromType.CartProduct }}
                                    />
                                </ProductItem>
                            ))}
                        </ProductListRow>
                    )}
                    {groupBrandItem?.totalResults > groupBrandItem?.data.length && <ButtonWrapper onClick={() => {
                        onViewMore({ brandId: groupBrandItem?.id, page: groupBrandItem.page + 1 });
                    }}>
                        <ButtonContainer>
                            <FormattedMessage {...messages.viewMore} />
                        </ButtonContainer>
                    </ButtonWrapper>}


                </BrandProducts>

            ))}
            <ModalNote
                headerLabel={selectedProduct?.name || ''}
                isOpen={isOpenNoteModal}
                confirmLoading={noteItemLoading}
                onSubmit={onSubmitAddingNote}
                onClose={onClickCloseNoteModal}
                note={selectedProduct?.note || ''}
            />

        </Container>
    )
};

const mapStateToProps = createStructuredSelector<ApplicationRootState, IStateProps>({
    loading: selectLoading(),
    addedProducts: selectAddedProducts(),
    addingProducts: selectAddingProducts(),
    permissions: selectUserPermissions(),
    noteItemLoading: selectNoteItemLoading(),
});
const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => ({
    onToggleFavorite: (data: IHomeFavoritePayload) => dispatch(setFavirote(data)),
    onSetProductNote: (data: IHomeProductNotePayload) => dispatch(setProductNote(data)),
    gotoSignIn: () => dispatch(push('/signin')),
    onViewMore: (data: { brandId: string, page: number }) =>
        dispatch(
            setViewMore(data),
        ),
});

const withConnect = connect<IStateProps, IDispatchProps, IOwnProps>(mapStateToProps, mapDispatchToProps);

export default withConnect(GroupBrandProductList);


