import { BrandProduct, ContainerActions, ContainerState, SimilarBrand } from './types';
import ActionTypes from './constants';

export const loadingResources = Array(10)
  .fill(0)
  .map<BrandProduct>((v, i) => ({
    loading: true,
    id: String(i),
    price: 0,
    originalPrice: 0,
    tags: [],
    isFavorite: false,
  }));

const loadingSimilarBrands = Array(5)
  .fill(0)
  .map<SimilarBrand>((v, i) => ({
    loading: true,
    id: String(i),
  }));

export const initialState: ContainerState = {
  loading: false,
  noteItemLoading: false,
  categories: [],
  similarBrands: loadingSimilarBrands,
  localLang: '',
  alternateLang: '',
  brandInfo: {
    id: '',
    logo: '',
    name: '',
    description: '',
    groupName: '',
    status: true
  },
  groupBrands: {},
  categoryIds: [],
};

function brandDetailsReducer(state: ContainerState = initialState, action: ContainerActions) {
  const { type, payload } = action as any;
  switch (type) {
    case ActionTypes.BRAND_DETAIL_REQUEST:
      return {
        loading: true,
        noteItemLoading: false,
        categories: [],
        similarBrands: loadingSimilarBrands,
        brandInfo: {
          id: '',
          logo: '',
          name: '',
          description: '',
          groupName: '',
        },
        groupBrands: {},
        categoryIds: [],
      };

    case ActionTypes.BRAND_DETAIL_SUCCESS:
      return { ...state, ...payload, loading: false, categories: payload.categories, brandInfo: payload.brandInfo, groupBrands: { ...state.groupBrands, ...payload.brandSection } };

    case ActionTypes.BRAND_DETAIL_FAILURE: {
      return { ...state, loading: false };
    }

    case ActionTypes.FETCH_BRAND_PRODUCTS_REQUEST:
      return { ...state, groupBrands: { ...state.groupBrands, ...payload } };

    case ActionTypes.FETCH_BRAND_PRODUCTS_SUCCESS: {
      return {
        ...state, groupBrands: {
          ...state.groupBrands, [Object.keys(payload)[0]]: {
            ...state.groupBrands[Object.keys(payload)[0]],
            ...payload[Object.keys(payload)[0]],
            data: payload[Object.keys(payload)[0]].page ? [...state.groupBrands[Object.keys(payload)[0]]?.data, ...payload[Object.keys(payload)[0]].data] : payload[Object.keys(payload)[0]].data,
          }
        }
      };
    }

    case ActionTypes.SET_PRODUCT_NOTE_REQUEST: {
      return { ...state, noteItemLoading: true };
    }

    case ActionTypes.SET_PRODUCT_NOTE_SUCCESS: {
      const { note, index, section } = payload;
      const products = [...state.groupBrands[section].data];
      products[index] = { ...products[index], note: note };
      return {
        ...state,
        groupBrands: { ...state.groupBrands, [section]: { data: products, error: false } },
        noteItemLoading: false,
      };
    }

    case ActionTypes.SET_PRODUCT_NOTE_FAILURE: {
      return { ...state, noteItemLoading: false };
    }

    case ActionTypes.SET_FAVORITE_SUCCESS: {
      const { index, section, isFavorite } = payload;
      const products = [...state.groupBrands[section].data];
      products[index] = { ...products[index], isFavorite: isFavorite };
      return {
        ...state,
        groupBrands: { ...state.groupBrands, [section]: { data: products, error: false } },
      };
    }

    case ActionTypes.FETCH_SIMILAR_BRANDS_SUCCESS:
      return {
        ...state,
        similarBrands: payload,
      };

    case ActionTypes.APPLY_FILTER:
      return {
        ...state,
        categoryIds: payload.categoryIds,
      };
    default:
      return state;
  }
}

export default brandDetailsReducer;
