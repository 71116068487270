import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styles/styled-components';
import SVGIcon from '../../../components/SVGIcon/SVGIcon';
import messages from '../messages';
import NotFoundIcon from './NotFoundIcon.svg';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 8px;
  background: #ffffff;
  padding: 20px;
  border-radius: 8px;
`;

const Image = styled(SVGIcon)``;

const Title = styled.h2`
  font-size: 28px;
  line-height: 36px;
  font-weight: 600;
  margin-top: 20px;
  color: #101828;
  @media (max-width: 767px) {
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
  }
`;
const SubText = styled.p`
  font-size: 16px;
  color: #757575;
  max-width: 550px;
  line-height: 24px;
`;

const NotFoundBrand = () => {
  return (
    <Container>
      <Image src={NotFoundIcon} />
      <Title>
        <FormattedMessage {...messages.notFoundTitle} />
      </Title>
      <SubText>
        <FormattedMessage {...messages.notFoundSubText} />
      </SubText>
    </Container>
  );
};

export default NotFoundBrand;
