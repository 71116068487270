enum ActionTypes {
  APPLY_FILTER = 'app/BrandDetail/APPLY_FILTER',
  BRAND_DETAIL_REQUEST = 'app/BrandDetail/BRAND_DETAIL_REQUEST',
  BRAND_DETAIL_SUCCESS = 'app/BrandDetail/BRAND_DETAIL_SUCCESS',
  BRAND_DETAIL_FAILURE = 'app/BrandDetail/BRAND_DETAIL_FAILURE',
  FETCH_BRAND_PRODUCTS_REQUEST = 'app/BrandDetail/FETCH_BRAND_PRODUCTS_REQUEST',
  FETCH_BRAND_PRODUCTS_SUCCESS = 'app/BrandDetail/FETCH_BRAND_PRODUCTS_SUCCESS',
  FETCH_BRAND_PRODUCTS_FAILURE = 'app/BrandDetail/FETCH_BRAND_PRODUCTS_FAILURE',
  FETCH_SIMILAR_BRANDS_REQUEST = 'app/BrandDetail/FETCH_SIMILAR_BRANDS_REQUEST',
  FETCH_SIMILAR_BRANDS_SUCCESS = 'app/BrandDetail/FETCH_SIMILAR_BRANDS_SUCCESS',
  FETCH_SIMILAR_BRANDS_FAILURE = 'app/BrandDetail/FETCH_SIMILAR_BRANDS_FAILURE',

  SET_PRODUCT_NOTE= 'app/BrandDetail/SET_PRODUCT_NOTE',
  SET_PRODUCT_NOTE_REQUEST = 'app/BrandDetail/SET_PRODUCT_NOTE_REQUEST',
  SET_PRODUCT_NOTE_SUCCESS = 'app/BrandDetail/SET_PRODUCT_NOTE_SUCCESS',
  SET_PRODUCT_NOTE_FAILURE = 'app/BrandDetail/SET_PRODUCT_NOTE_FAILURE',

  SET_FAVORITE = 'app/BrandDetail/SET_FAVORITE',
  SET_FAVORITE_REQUEST = 'app/BrandDetail/SET_FAVORITE_REQUEST',
  SET_FAVORITE_SUCCESS = 'app/BrandDetail/SET_FAVORITE_SUCCESS',
  SET_FAVORITE_FAILURE = 'app/BrandDetail/SET_FAVORITE_FAILURE',

  VIEW_MORE = 'app/BrandDetail/VIEW_MORE',
}
export default ActionTypes;
