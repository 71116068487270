import React, { useMemo, useState } from 'react';
import styled from 'styles/styled-components';
import media from 'utils/mediaStyle';
import { BrandInfo, IGroupBrands } from '../types';
import { FormattedMessage } from 'react-intl';
import messages from '../messages';
import { Viewport } from 'components/ViewportProvider';
import { getInitials } from 'utils/getInitialsBrandName';
import { Link } from 'react-router-dom';
import { generateUrlBrandDetail } from '../../../utils/generateBrandDetailUrl';

const Container = styled.div`
  display: flex;
  align-self: stretch;
  gap: 12px;
  border-radius: 12px;
  background-color: #ffffff;
  width: 100%;
  align-items: flex-start;

  ${media.md`
    gap: 20px;
    padding: 20px;
  `};
`;

const InfoContainer = styled.div`
  flex: 1 1 0%;
  gap: 4px;
  ${media.md`
    gap: 8px;
  `};
`;

const BrandName = styled.div`
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  color: #242527;

  ${media.md`
    font-size: 28px;
    font-weight: 600;
    line-height: 36px;
  `};
`;
const DescriptionContainer = styled.p`
  position: relative;
`;

const DescriptionContent = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: #585a60;
  font-weight: 400;

  a {
    color: #43a047;

    &:hover {
      color: #43a047;
      text-decoration: underline;
    }
  }
`;

const ReadMoreLessButton = styled.span`
  font-size: 16px;
  line-height: 24px;
  color: #43a047;
  font-weight: 400;
  cursor: pointer;
  display: ${(props) => (props.isReadMore ? 'inline-block' : 'block')};
`;

const ImageContainer = styled.div`
  width: 68px;
  height: 68px;
  font-size: 18px;
  border-radius: 12px;
  border: 1px solid #eaeaeb;
  ${media.md`
    font-size: 20px;
    width: 120px;
    height: 120px;
  `};

  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  color: #242527;
`;

const Image = styled.img`
  border-radius: 12px;
  object-fit: contain;
  width: 100%;
  height: 100%;
`;

const GroupBrandContainer = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 8px;
`

const GroupBrandTitle = styled.div`
    padding-top: 14px;
    font-size: 16px;
    line-height: 24px;
    color: #585A60;
    white-space: nowrap;
`

const GroupBrandItems = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 20px;
  row-gap: 10px;
`

const GroupBrandItemLogoContainer = styled.div`
  cursor: pointer;
  width: 52px;
  height: 52px;
  font-size: 16px;
  border-radius: 50%;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  
  ${media.md`
    font-size: 18px;
  `};

  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  color: #242527;
`

const GroupBrandItemLogo = styled.img`
  border-radius: 50%;
  object-fit: contain;
  width: 100%;
  height: 100%;
`
interface IProps {
  brandInfo: BrandInfo;
  groupBrands: IGroupBrands;
}

const Description = ({ content, maxLength }: { content: string; maxLength: number }) => {
  const [isReadMore, setIsReadMore] = useState(true);

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  const displayText = useMemo(() => {
    return isReadMore ? content.slice(0, maxLength) : content;
  }, [content, isReadMore, maxLength]);

  return (
    <DescriptionContent>
      <span dangerouslySetInnerHTML={{
        __html: displayText
      }} />
      {content.length > maxLength && (
        <ReadMoreLessButton isReadMore={isReadMore} onClick={toggleReadMore}>
          {isReadMore ? (
            <FormattedMessage {...messages.readMore} defaultMessage="...Xem thêm" />
          ) : (
            <FormattedMessage {...messages.collapse} defaultMessage="Thu gọn" />
          )}
        </ReadMoreLessButton>
      )}
    </DescriptionContent>
  );
};

const BrandInfo = ({ brandInfo, groupBrands }: IProps) => {
  return (
    <Viewport.Consumer>
      {({ width }) => (
        <Container>
          <ImageContainer>
            {brandInfo?.logo ? <Image src={brandInfo?.logo} loading="lazy" /> : getInitials(brandInfo.name)}
          </ImageContainer>
          <InfoContainer>
            <BrandName>{brandInfo?.name}</BrandName>
            {brandInfo?.description && <DescriptionContainer>
              <Description
                content={brandInfo?.description || ''}
                maxLength={width > 1024 ? 350 : width >= 768 ? 250 : 120}
              />
            </DescriptionContainer>}
            {width >= 768 && brandInfo?.groupName && Object.keys(groupBrands)?.map((brandId) => {
              return {
                id: groupBrands[brandId].id,
                data: groupBrands[brandId].data,
              }
            }).filter((item) => item.data.length > 0 && item.id !== brandInfo.id).length > 0 && <GroupBrandContainer>
                <GroupBrandTitle><FormattedMessage {...messages.brandFrom} defaultMessage="Thương hiệu từ" /> {brandInfo.groupName}</GroupBrandTitle>
                <GroupBrandItems>
                  {Object.keys(groupBrands)?.map((brandId) => (
                    brandId !== brandInfo.id && groupBrands[brandId].totalResults > 0 &&
                    <Link to={generateUrlBrandDetail(groupBrands[brandId].id, groupBrands[brandId].name as string)} key={groupBrands[brandId].id}>
                      <GroupBrandItemLogoContainer>
                        {groupBrands[brandId].logo ? <GroupBrandItemLogo src={groupBrands[brandId].logo} loading="lazy" /> : getInitials(groupBrands[brandId].name as string)}
                      </GroupBrandItemLogoContainer>
                    </Link>
                  ))}
                </GroupBrandItems>
              </GroupBrandContainer>}

          </InfoContainer>
        </Container>
      )}
    </Viewport.Consumer>
  );
};

export default BrandInfo;
