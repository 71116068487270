import { action, createAsyncAction } from 'typesafe-actions';
import ActionTypes from './constants';

export const brandDetail = createAsyncAction(
  ActionTypes.BRAND_DETAIL_REQUEST,
  ActionTypes.BRAND_DETAIL_SUCCESS,
  ActionTypes.BRAND_DETAIL_FAILURE,
)<void, {}, Error>();

export const brandProducts = createAsyncAction(
  ActionTypes.FETCH_BRAND_PRODUCTS_REQUEST,
  ActionTypes.FETCH_BRAND_PRODUCTS_SUCCESS,
  ActionTypes.FETCH_BRAND_PRODUCTS_FAILURE,
)<{}, {}, Error>();

export const similarBrands = createAsyncAction(
  ActionTypes.FETCH_SIMILAR_BRANDS_REQUEST,
  ActionTypes.FETCH_SIMILAR_BRANDS_SUCCESS,
  ActionTypes.FETCH_SIMILAR_BRANDS_FAILURE,
)<void, {}, Error>();

export const setApplyFilter = (data: any) => action(ActionTypes.APPLY_FILTER, data);

export const setFavirote = (data: any) => action(ActionTypes.SET_FAVORITE, data);
export const brandDetailFavorite = createAsyncAction(
  ActionTypes.SET_FAVORITE_REQUEST,
  ActionTypes.SET_FAVORITE_SUCCESS,
  ActionTypes.SET_FAVORITE_FAILURE,
)<void, {}, {}>();

export const setProductNote = (data: any) => action(ActionTypes.SET_PRODUCT_NOTE, data);
export const brandDetailSetProductNote = createAsyncAction(
  ActionTypes.SET_PRODUCT_NOTE_REQUEST,
  ActionTypes.SET_PRODUCT_NOTE_SUCCESS,
  ActionTypes.SET_PRODUCT_NOTE_FAILURE,
)<void, {}, Error>();

export const setViewMore = (data: any) => action(ActionTypes.VIEW_MORE, data);
